import React from 'react'
import Hero from './Hero'
import Services from './Services'
import Clients from './Clients'
import FAQ from './FAQ'
import Testimonials from './Testimonials'

const Home = () => {

  return (
    <main>
      <Hero />
      <Services />
      <Clients />
      <FAQ />
      <Testimonials />
    </main>
  )
}

export default Home;