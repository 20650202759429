import React from 'react';
import { Clients, accessibility, tick } from '../../Assets';
import FlyoutLink from '../../Components/Flyoutlink';

const XmlService = () => {
  return (
    <div className='font-Archivo text-justify text-sm md:text-base text-[#26313C]'>
      <div className="h-[40vh] md:h-[60vh] w-full bg-cover bg-center relative" style={{ backgroundImage: `url(${Clients})`}} >
        <div className="bg-black-80 h-full w-full flex justify-center items-center">
          <p className='text-2xl md:text-3xl lg:text-5xl text-white font-bold animate-fadeInUp text-center font-Jose tracking-wider'>ACCESSIBILITY SERVICE</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:mt-10 mt-3 px-6 md:px-10">
        <div className="font-normal text-md animate-fadeInLeft">
          <p className='leading-7 tracking-wide'> The transition of technology over the last few years has transformed, refined and redefined the way people can access different kinds of data and information these days. While it was next to impossible for people with disabilities to access data and information in the past, it is simple and easy these days. The emergence of accessibility services has facilitated this change to a greater extent. </p>
          <FlyoutLink className='text-blue-900'><p className='text-3xl mt-5 md:mt-10 text-blue-900 font-bold uppercase font-Manrope tracking-wider '>WEB Accessibility</p></FlyoutLink>
          <p className="mt-5 leading-7 tracking-wide"> The WCAG or the Web Content Accessibility Guidelines 2.0 not only applies to web pages but also to the things that customers download from a website. Added to their years of experience, our staff members also employ innovative practices while providing solutions to problems in this category. They keep abreast with the up-to-date information about the latest technologies to provide our clients with top-notch services in the industry. Site structure, hyperlinks and navigation aside, they also make sure that the text and media on the websites of our clients are accessible to people with disabilities.
          </p>
        </div>
        <div className="flex justify-center items-center animate-fadeInRight">
          <img src={accessibility} alt="XML Service" className="w-72 sm:w-96 md:w-96 transition-transform duration-300 hover:scale-105" />
        </div>
      </div>
      <div className='px-10'>       
        <FlyoutLink className='text-blue-900'><p className='text-3xl mt-5 md:mt-10 text-blue-900 font-bold uppercase font-Manrope tracking-wider'>PDF ePUB</p></FlyoutLink>
        <p className="mt-5 leading-7 tracking-wide"> For users with disabilities, a PDF file brings several options to the table. By modifying the structural hierarchy and displaying the contents of various nodes separately, we simplify the process of accessing the information of an entire document for people with disabilities. From the use of screen readers like JAWS to Adobe Acrobat Quick Accessibility Check, our services cover all bases with regard to the availability of the information in PDF documents. </p>
        <p> In our efforts to create a more accessible PDF, we fully comply with the guidelines of PDF/UA. Also, we offer a detailed report on the whether or not the PDF files on our clients' websites are accessible to people with disabilities. The report also includes suggestions to address the existing issues. </p>
        <FlyoutLink className='text-blue-900'><p className='text-3xl mt-5 md:mt-10 text-blue-900 font-bold uppercase font-Manrope tracking-wider'>Accessibility ePUB</p></FlyoutLink>
        <p className="mt-5 leading-7 tracking-wide"> Our services of ePUB include files of various formats that are available on the internet. We are aware of the critical importance for people of all abilities to access the information stored in them on the go.</p>
        <p className="mt-5 leading-7 tracking-wide">In collaboration with modern techniques such as WCAG 2.0, ePUB Distributable Objects and more, we provide a simple and easy alternative to differently abled users to accessing text, video, animation and audio of their choice.</p>
      </div>
      <div className='px-8'>
        <FlyoutLink className='text-blue-900'><p className='text-3xl mt-5 md:mt-10 text-blue-900 font-bold uppercase font-Manrope tracking-wider  '>Section 508 compliance</p></FlyoutLink>
        <p className="mt-5 leading-7 tracking-wide"> We attach a great degree of importance to compliance with WCAG 2.0 accessibility guidelines and Section 508 standards. Therefore, we use the following tools to ensure PDF accessibility:</p>
        <div className='mt-5 flex flex-col gap-4 px-2 md:px-5 leading-7 tracking-wide'>
          <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Adobe Acrobat Full Accessibility</p></div>
          <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Adobe Acrobat Quick Accessibility Check </p></div>
          <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Third-party tools for verification wherever and whenever necessary</p></div>
          <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Screen readers like JAWS</p></div>
          <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Adobe Read - Aloud</p></div>
        </div>
        <p className="mt-5 leading-7 tracking-wide"> With an eye out for Section 508 compliance, we also perform the following tasks: </p>
        <div className='mt-5 flex flex-col gap-4 px-2 md:px-5 leading-7 tracking-wide'>
          <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Assisting content authors with remediation methods</p></div>
          <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Conduct audits on a regular basis to support on-going accessibility</p></div>
          <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Provide extra staff members to your existing workforce for additional help.</p></div>
        </div>
      </div>      
    </div>
  )
}
export default XmlService;
