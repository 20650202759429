import React from 'react';
import { Clients, epub, tick } from '../../Assets';
import FlyoutLink from '../../Components/Flyoutlink';

const XmlService = () => {
  return (
    <div className='font-Archivo text-[#26313C] text-justify text-sm md:text-base'>
      <div className="h-[40vh] md:h-[60vh] w-full bg-cover bg-center relative" style={{ backgroundImage: `url(${Clients})` }} >
        <div className="bg-black-80 h-full w-full flex justify-center items-center">
          <p className='text-2xl md:text-3xl lg:text-5xl text-white font-bold animate-fadeInUp font-Jose tracking-wider text-center'> E-BOOK PUBLISHING </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:mt-10 mt-3 px-6 md:px-10">
        <div className="font-normal animate-fadeInLeft">
          <p className='leading-7 tracking-wide'> eDigital Infosolutions Private Limited is the leader when it comes to e-publishing services. Together with expertise, our workforce also specializes in the processes involving management, delivery and creation of content according to the demand of our customer or clients We provide outstanding support and solutions to various forms of conversion such as E-book conversion, XML conversion and HTML5 conversion. </p>
          <FlyoutLink className='text-blue-900'><p className='text-3xl mt-5 md:mt-10 text-blue-900 font-bold uppercase tracking-wider font-Manrope'>EPUB and MOBI</p></FlyoutLink>
          <p className="mt-3 md:mt-5 leading-7 tracking-wide"> While MOBI files are compatible with all kinds of Kindle products, EPUB files can be read only on an iPad, Nook or any other eReader. When a file is converted into EPUB or MOBI files, its content remains the same but its resolution gets optimised in terms of the screen size of various devices. Our experts can accomplish the task perfectly for you. </p>
        </div>
        <div className="flex justify-center items-center animate-fadeInRight">
          <img src={epub} alt="XML Service" className="w-72 sm:w-96 md:w-[500px] transition-transform duration-300 hover:scale-105" />
        </div>
      </div>
      <div className='px-10'>
        <div>
          <FlyoutLink className='text-blue-900'><p className='text-3xl mt-10 text-blue-900 font-bold uppercase font-Manrope tracking-wider'>eBook Conversion</p></FlyoutLink>
          <p className="mt-5 leading-7 tracking-wide"> While MOBI files are compatible with all kinds of Kindle products, EPUB files can be read only on an iPad, Nook or any other eReader. When a file is converted into EPUB or MOBI files, its content remains the same but its resolution gets optimised in terms of the screen size of various devices. Our experts can accomplish the task perfectly for you. </p>
          <div className='mt-5 flex flex-col gap-4 md:px-5 leading-7 tracking-wide'>
            <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>The ability to search text using particular words or phrases</p></div>
            <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>The feature to choose the style and size of fonts to enhance one’s reading experience</p></div>
            <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Dark and bright reading modes</p></div>
            <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Digital bookmarks to get back to a page in accordance with one’s needs</p></div>
            <div className='md:flex grid grid-cols-12 gap-3'><span className='col-span-2'><img className='w-6 h-auto' src={tick} alt='tick' /></span><p className='col-span-10'>Tables of contents that are dynamic by nature</p></div>
          </div>
        </div>
        <FlyoutLink className='text-blue-900'><p className='text-3xl mt-10 text-blue-900 font-bold font-Manrope tracking-wider'>Fixed layout EPUB/MOBI</p></FlyoutLink>
        <p className="mt-5 leading-7 tracking-wide"> Standard eBooks i.e. mobi and ePub involve a flowable layout for images and text. As a result, the appearance of an eBook may vary from one eReader to the other. On the other hand, those variants that consist of Fixed Layout affix images to each electronic page. So, regardless of the kind of eReader on which they are viewed, they look the same on all the eReaders. </p>
        <p> If you wish to appear the text and images on an eBook in a particular way, then a Fixed Layout file is what you need. Having worked in the industry for several years, our technical experts have the expertise to convert .mobi to eBooks with a Fixed Layout so you can view it on your Apple device in the way you want without any hassle.</p>
        <FlyoutLink className='text-blue-900'><p className='text-3xl mt-10 text-blue-900 font-bold font-Manrope tracking-wider'>Read Aloud ePub</p></FlyoutLink>
        <p className="mt-5 leading-7 tracking-wide">The read aloud feature is one of the highlights of the devices that are designed and developed by Apple Inc. It spices up the experience of reading eBooks by adding the element of fun to it. It usually involves a background music which is soothing to the ears and further encourages a reader to read on. Being in the industry for several years and with the experience of working with this feature, our technical experts are thoroughly acquainted with it. They are committed to helping you make the most of this feature with their services.</p>
      </div>      
    </div>
  )
}
export default XmlService;
