import React, { useEffect, useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const Clients = () => {

    const counterRef = useRef(null);
    const isInView = useInView(counterRef, { once: true, amount: 0.5 });

    useEffect(() => {

        if (!isInView) return;

        const counters = counterRef.current.querySelectorAll('.count');

        const speed = 97;

        counters.forEach((counter) => {
            const updateCount = () => {
                const target = Number(counter.getAttribute('data-target'));
                const count = Number(counter.innerText);
                const increment = target / speed;

                if (count < target) {
                    counter.innerText = Math.floor(increment + count);
                    setTimeout(updateCount, 15);
                } else {
                    counter.innerText = target;
                }
            };

            updateCount();
        })

    }, [isInView]);

    return (
        <div  ref={counterRef} className="bg-cover bg-center bg-no-repeat relative py-10 lg:px-40 lg:py-24 px-1 md:px-[9%]">
            <p className="text-md md:text-2xl px-4 xl:px-32 text-justify md:text-center mb-5 lg:mb-20 font-Manrope tracking-wider lg:tracking-widest text-[#26313C] font-semibold text">Celebrating the trust of thousands of happy customers each month, showcasing our commitment to <br />satisfaction & quality</p>
            <div className="absolute top-0 left-0 w-full h-full opacity-70 z-10" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 relative z-20 text-center text-gray-500">
                {[
                    { target: 1254, label: 'PDF To XML' },
                    { target: 12168, label: 'PDF To EPUB' },
                    { target: 2172, label: 'A PDF' },
                    { target: 732, label: 'TYPESETTING' },
                ].map((item, i) => (
                    <motion.div key={i} className="relative text-blue-900 mt-3 lg:mt-1 font-Manrope" initial={{ opacity: 0, y: 50 }} animate={isInView ? { opacity: 1, y: 0 } : {}} transition={{ duration: 3.6, delay: i * 0.2 }} >
                        <h1 className="count text-2xl lg:text-5xl md:mb-4" data-target={item.target}>0</h1>
                        <p className="text-lg font-bold">{item.label}</p>
                        <div className="absolute bottom-[-1rem] lg:bottom-[-2rem] left-1/2 w-1/5 h-1 bg-gray-400 rounded transform -translate-x-1/2" />
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default Clients;