  import React from 'react';
  import {
    gImage1, gImage2, gImage3, gImage4, gImage5, gImage6, gImage7, gImage8, gImage9, gImage10, 
  } from '../../Assets/index';

  const Gallery = () => {
    const GalleryImages = [
      { src: gImage1, name: 'Serenity' },
      { src: gImage2, name: 'Bliss' },
      { src: gImage3, name: 'Harmony' },
      { src: gImage4, name: 'Tranquility' },
      { src: gImage5, name: 'Euphoria' },
      { src: gImage6, name: 'Zen' },
      { src: gImage7, name: 'Radiance' },
      { src: gImage8, name: 'Solitude' },
      { src: gImage9, name: 'Wanderlust' },
      { src: gImage10, name: 'Dream scape' },
    ];

    return (
      <div className='container mx-auto pt-12 lg:pt-24 bg-gray-50 '>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-1 md:py-8 px-2 md:px-4 lg:px-6 animate-fadeInLeft' >
          <div className='text-3xl md:text-4xl lg:text-5xl font-semibold leading-normal text-gray-800 font-Pop tracking-wide'> We Make Our Projects Simple & Efficient – Experience the Difference </div>
          <div className='text-base md:text-lg text-gray-600 text-justify font-Pop font-medium'> At our core, we focus on delivering solutions that emphasize simplicity and efficiency. Our team ensures every project is handled with meticulous care, producing results that reflect our commitment to quality.</div>
        </div>
        <div className='grid grid-cols-3 sm:grid-cols-4 gap-3 lg:gap-8 mt-6 lg:mt-12 bg-gray-100 md:px-12 lg:px-28 h-[120vh] lg:py-24 px-3 lg:animate-fadeInRight'>
          {GalleryImages.map((item, index) => (
            <div className='relative overflow-hidden rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 transform hover:scale-105' key={index} >
              <img className='w-full h-full object-cover transition-transform duration-500 ease-in-out' src={item.src} alt={item.name} />
              <div className='absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-500 flex items-center justify-center'>
                <p className='text-white font-semibold text-sm'>{item.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  export default Gallery;
