import React from 'react';
import { Clients, xml } from '../../Assets';

const XmlService = () => {
  return (
    <div>
      <div className="h-[40vh] md:h-[60vh] w-full bg-cover bg-center" style={{ backgroundImage: `url(${Clients})`}} >
        <div className='bg-black-80 h-full w-full flex justify-center items-center'>
          <p className='text-2xl md:text-3xl lg:text-5xl text-white font-bold animate-fadeInUp font-Jose tracking-wider text-center'>XML CONVERSION</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:mt-10 mt-3 px-6 md:px-10 text-sm md:text-base">
        <div className='md:p-10 p-5 animate-fadeInLeft leading-7 tracking-wide font-Archivo text-[#26313C] text-justify'>
          <p>XML, or Extensible Markup Language, is a meta language which lets users choose their own markup languages to display different documents online. Our services in XML conversion includes conversion of the files based on certain specific formats such as HTML, PDF, TEXT and JPEG into an XML format.</p>
          <p className='mt-4'>Designed to be a cross-platform/multi-platform technology, the objective of our conversion services in this regard is to help you leverage the graphical interactions and rich flash animation. We use the capabilities of flash to help you transition to HTML5 for enhancing your experience on various device.</p>
        </div>
        <div className="flex justify-center items-center animate-fadeInRight">
          <img src={xml} alt="XML Service" className="w-72 sm:w-96 md:w-[500px] transition-transform duration-300 hover:scale-105" />
        </div>
      </div>  
    </div>
  )
}
export default XmlService;
