import React from 'react';
// import { aboutusImg } from '../../Assets';
import Ourmoto from './Ourmoto';
import PIC from "../../Assets/Images/about-us.jpg";

const AboutUs = () => {
  return (
    <div className="bg-[#F5FCFE]">
      <div className="h-[60vh] w-full bg-cover bg-center" style={{ backgroundImage: `url(${PIC})`}} >
        <div className=' bg-black-80 h-full w-full flex flex-col justify-center items-center'>
          <p className='text-2xl md:text-3xl lg:text-5xl text-white font-bold animate-fadeInUp font-Jose tracking-wider'>ABOUT US</p>
          <p className='md:px-48 text-justify md:text-center text-white mt-4 animate-fadeInUp px-2 font-Pop tracking-wider text-sm md:text-md'>With a passion for excellence and a vision for the future, Agaram Publishing and Technologies stands at the forefront of the publishing industry, harnessing cutting-edge technology to bring your ideas to life</p>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-4 mt-4 md:mt-10'>
        <div className='p-3 md:p-10 animate-fadeInLeft leading-7 tracking-wide text-justify font-Archivo text-[#26313C] text-sm md:text-base'>
          <p>Agaram Publishing is a startup founded by aspiring software professional, who worked extensively in enterprise software consulting and ePublishing. With much passion for the paced growth of the web application and allied professionals who worked extensively in enterprise software consulting.</p>
          <p className='mt-4'>The timing is right for this venture. The founders who are young entrepreneurs have always dreamt of bringing up technology to the common man which narrowed down to the choice of Application Development, e-publishing and content management space. With literacy the only tool that improves the life of the common man in growing economies like India, catering to the e-publishing space with all the expertise of the founders would be a social responsibility of our great nations.</p>
          <p className='mt-4'>The leaders have around 15 years of working experience in public and private sectors in India, the strong relationship with potential contacts in the software and biz community in India. The core values of this enterprise are to provide distinct service and build core competence in the application development and content management space thereby catering to the various publishers.</p>
          <p className='mt-4'>Agaram Publishing with a noble and ambitious vision of providing optimal technology solutions to clients with the highest emphasis on quality, integrity and timeliness.</p>
          <p className=''>Our long term vision of diversification led to Agaram venturing Staffing Services, Product development for Textile industry & Document management system. This has led to the organization increase its visibility and expertise in multiple areas and put us in good stead in handling assignments of highly critical nature.</p>
        </div>
      </div>
      <div><Ourmoto /></div>
    </div>
  )
}
export default AboutUs;
