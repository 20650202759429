import { useMotionValue, motion, useSpring, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { FiArrowRight } from "react-icons/fi";
import { xml, epub, accessibility, service1 } from '../../Assets/index';


const Services = () => {

    return (
        <div className="h-full w-full m-0 p-0">
            <p className="text-2xl md:text-3xl lg:text-5xl font-semibold mt-8 md:mt-16 uppercase px-7 md:px-16 text-[#26313C] font-Jose">Our services</p>

            <section className=" text-black-text h-auto bg-white border p-4 lg:px-8 lg:pt-16 lg:pb-28 mt-5 lg:mt-10 mx-3 lg:mx-16 md:mx-5 backdrop-blur-md rounded-lg shadow-lg">
                <div className="mx-auto max-w-5xl">
                    <Link heading="XML - CONVERSION" subheading="We can also handle any form of electronic input, from application files to PDF to XML. We work with a vast range of content types and formats and can handle any type of conversion, including DTD to DTD, Web-based conversion and XML/SGML and HTML projects." imgSrc={xml}  href="XmlService" />
                    <Link heading="E-PUBLISHING" subheading="Our eBook workflow, we convert all your inputs to the ePub format, the basis of all other eBook formats, using an automated process assisted by especially developed tools. We then carry out the quick and simple second-step conversion to the format of your choice."  imgSrc={epub} href="EpubService" />
                    <Link heading="ACCESSIBILITY" subheading="eReading applications ensure accessibility by factors like semantics, SVG, metadata, Text-to-speech, style sheets, navigation, scripted interactivity, media overlays and other factors." imgSrc={accessibility} href="AccessibilityService" />
                    <Link  heading="TYPE SETTING" subheading="Our eBook workflow, we convert all your inputs to the ePub format, the basis of all other eBook formats, using an automated process assisted by especially developed tools. We then carry out the quick and simple second-step conversion to the format of your choice."  imgSrc={service1} href="TypeSettingService" />
                </div>
            </section>

        </div>
    );
};

export default Services;

const Link = ({ heading, imgSrc, subheading, href }) => {

    const ref = useRef(null);
    const x = useMotionValue(0);
    const y = useMotionValue(0);
    const mouseXSpring = useSpring(x);
    const mouseYSpring = useSpring(y);
    const top = useTransform(mouseYSpring, [0.5, -0.5], ["40%", "60%"]);
    const left = useTransform(mouseXSpring, [0.5, -0.5], ["60%", "70%"]);

    const handleMouseMove = (e) => {
        const rect = ref.current.getBoundingClientRect();
        const width = rect.width;
        const height = rect.height;
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;
        const xPct = mouseX / width - 0.5;
        const yPct = mouseY / height - 0.5;
        x.set(xPct);
        y.set(yPct);
    };

    return (
        <motion.a href={href} ref={ref} onMouseMove={handleMouseMove} initial="initial" whileHover="whileHover" className="w-full group md:relative md:flex gap-5 items-center justify-between border-b-2 border-neutral-700 py-1 transition-colors duration-500 hover:border-neutral-50 xl:py-12" >
            <div className="lg:flex justify-between mt-3 md:mt-1 gap-12">
                <div> 
                    <motion.span variants={{ initial: { x: 0 }, whileHover: { x: 0 }, }} transition={{ type: "spring", staggerChildren: 0.075, delayChildren: 0.25,}} className="text-gray-700 relative z-10 block text-xl font-bold transition-colors duration-500 group-hover:text-blue-900 md:text-4xl lg:text-5xl font-Dosis" >
                        {heading.split("").map((l, i) => (
                            <motion.span variants={{ initial: { x: 0 }, whileHover: { x: 16 },}} transition={{ type: "spring" }} className="inline-block " key={i} > {l} </motion.span>
                        ))}
                    </motion.span>
                    <span className="leading-6  tracking-wide font-normal relative z-10 mt-4 md:mt-7 mb-7 block text-xs lg:text-base text-neutral-800 transition-colors duration-500 group-hover:text-black-text font-Pop text-justify"> {subheading} </span>
                </div>
                <motion.img style={{ top, left, translateX: "-10%",translateY: "-10%" }} variants={{ initial: { scale: 0, rotate: "-10.5deg" }, whileHover: { scale: 1, rotate: "5.5deg" }, }} transition={{ type: "spring", stiffness: 100 }} src={imgSrc} className="h-1 w-32 rounded-lg object-cover lg:h-48 md:w-64 z-10 md:flex hidden" alt={`Image representing a link for ${heading}`} />
            </div>
            <motion.div variants={{ initial: { x: "25%",  opacity: 0 },  whileHover: { x: "0%", opacity: 1, }}} transition={{ type: "spring" }} className="relative z-10 p-4 md:block hidden">
                <FiArrowRight className="text-5xl text-neutral-50" />
            </motion.div>
        </motion.a>
    );
};