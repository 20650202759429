import React from 'react';
import { motion } from "framer-motion";
import Logo from "../Assets/Images/agramLogo.png";
import { SiFacebook, SiWhatsapp, SiLinkedin, SiInstagram } from "react-icons/si";

const Block = ({ className = "", ...rest }) => {
    return (
      <motion.div className={`col-span-4 rounded-lg border border-sandal-80p-1 md:p-1 mg:p-3 ${className}`}{...rest}
        variants={{
          initial: { scale: 0.5, y: 50, opacity: 0 },
          animate: { scale: 1, y: 0, opacity: 1 },
        }}
        transition={{
          type: "spring",
          mass: 3,
          stiffness: 400,
          damping: 50,
        }}      
      />
    );
};

const Footer = () => {
    return (
        <>
          <div className="py-5 md:py-10 mt-6 bg-white border shadow-md rounded-t-2xl font-Arvo text-sm md:text-md" id='contact'>
            <div className="container mx-auto px-4">
              <div className="md:flex justify-around">
                <div className="mb-8">
                  <h3 className="font-bold text-2xl font-cursive tracking-wide">
                    <a href='/'><img src={Logo} width={200} className='object-cover' alt='Agaram-logo' /></a>
                  </h3>  
                  <p className="text-gray-600 my-4 text font-Pop hidden">Agaram Publishing and Services stands at the<br /> forefront of the publishing industry, harnessing cutting-edge <br />technology to bring your ideas to life</p>
                  <div className='flex justify-start gap-5 pt-4'>
                    <Block className="col-span-6 bg-[#0077b5] md:col-span-3">
                      <a href="https://www.linkedin.com/" rel='noreferrer' target="_blank" className="p-1 md:p-0 grid h-full place-content-center text-3xl text-white">
                        <SiLinkedin />
                      </a>
                    </Block>
                    <Block className="col-span-6 bg-green-600 md:col-span-3">
                      <a href="https://www.whatsapp.com" target="_blank" rel='noreferrer' className="p-1 md:p-0 grid h-full place-content-center text-3xl text-white">
                        <SiWhatsapp />
                      </a>
                    </Block>
                    <Block className="col-span-6 bg-[#395498] md:col-span-3">
                      <a href="https://www.facebook.com" target="_blank" rel='noreferrer' className="p-1 md:p-0 grid h-full place-content-center text-3xl text-white">
                        <SiFacebook />
                      </a>
                    </Block>
                    <Block className="col-span-6 bg-pink-800 md:col-span-3">
                      <a href="https://www.instagram.com" target="_blank" rel='noreferrer' className="p-1 md:p-0 grid h-full place-content-center text-3xl text-black">
                        <SiInstagram className="text-white"/>
                      </a>
                    </Block>  
                  </div>
                </div>
                <div className='md:flex justify-around w-full'>
                  <div className="mb-8">
                    <h5 className="text-blue-80 font-extrabold font-Manrope text-base tracking-wider">Quick Links</h5>
                    <ul className="list-none p-0 ml-0.5 font-Mont font-medium mt-1">
                      <li className="py-0.5 text-gray-800 hover:text-blue-80 hover:scale-105 transition-transform duration-300"><a className="text-gray-800 hover:text-blue-80" href="/">Home</a></li>
                      <li className="py-0.5 text-gray-800 hover:text-blue-80 hover:scale-105 transition-transform duration-300"><a className="text-gray-800 hover:text-blue-80" href="/aboutus">About Us</a></li> 
                      <li className="py-0.5 text-gray-800 hover:text-blue-80 hover:scale-105 transition-transform duration-300"><a className="text-gray-800 hover:text-blue-80" href="/gallery">Gallery</a></li>
                      <li className="py-0.5 text-gray-800 hover:text-blue-80 hover:scale-105 transition-transform duration-300"><a className="text-gray-800 hover:text-blue-80" href="#contact">Contact Us</a></li>
                    </ul>
                  </div>
                  <div className="mb-8">
                    <h5 className="text-blue-80 font-extrabold font-Manrope text-base tracking-wider">Our Services</h5>
                    <ul className="list-none ml-0.5 p-0 font-Mont font-medium mt-1 uppercase">
                      <li className="py-1 text-gray-800 hover:text-blue-80 hover:scale-105 transition-transform duration-300"><a className="text-gray-800 hover:text-blue-80" href="/xmlService">XML Conversion</a></li>
                      <li className="py-1 text-gray-800 hover:text-blue-80 hover:scale-105 transition-transform duration-300"><a className="text-gray-800 hover:text-blue-80" href="/epubService">E-Publishing</a></li>
                      <li className="py-1 text-gray-800 hover:text-blue-80 hover:scale-105 transition-transform duration-300"><a className="text-gray-800 hover:text-blue-80" href="/accessibilityService">Accessibility</a></li>
                      <li className="py-1 text-gray-800 hover:text-blue-80 hover:scale-105 transition-transform duration-300"><a className="text-gray-800 hover:text-blue-80" href="/TypeSettingService">Typesetting</a></li>
                    </ul>
                  </div>
                  <div className="mb-8 font-Mont">
                    <h5 className="text-blue-80 font-extrabold font-Manrope text-base tracking-wider">Contact Info</h5>
                    <p className="flex items-center py-1 text-gray-800 font-medium mt-1"><i className="fa-solid fa-phone-volume mr-0.5"></i> Agaram Publishing and Services (OPC) Pvt. Ltd.</p>
                    <p className="flex items-center py-1 text-gray-800 font-medium"><i className="fa-solid fa-phone-volume mr-0.5"></i> Old No. 2A, New No. 10, Ramanathan Street,</p>
                    <p className="flex items-center py-1 text-gray-800 font-medium"><i className="fa-solid fa-envelope mr-0.5"></i> Krishnapuram, Ambattur</p>
                    <p className="flex items-center py-1 text-gray-800 font-medium"><i className="fa-solid fa-paper-plane mr-0.5"></i> Chennai - 600053, India. </p>
                    <p className="flex items-center py-1 text-gray-800 font-medium"><i className="fa-solid fa-paper-plane mr-0.5"></i> Phone: +91-8056412320, +91-44-35000701 </p>
                    <p className="flex items-center py-1 text-gray-800 font-medium"><i className="fa-solid fa-paper-plane mr-0.5"></i> Email: info@agaram.co.in </p>
                  </div>  
                </div>                  
              </div>
            </div>
          </div>
          <div className="bg-sandal-90 text-center py-2 font-Manrope tracking-wider">
              <p className="text-white font-bold text-sm">&copy; Copyright by <a target='_blank' rel='noreferrer' href='https://eloiacs.com/'>Eloiacs</a></p>
          </div>
        </>
    );
};

export default Footer;
