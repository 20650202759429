import React, { useState } from 'react';
import { AnimatePresence, motion, useMotionValueEvent, useScroll } from "framer-motion";
import Logo from "../Assets/Images/agramLogo.png";
import { Link } from 'react-router-dom';
import SpringModal from '../Pages/ContactForm/Form';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    setScrolled(latest > 250 ? true : false);
  });

  const openModal = () => {
    setIsOpen(true);
    setMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(prev => !prev);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <div>
      <SpringModal isOpen={isOpen} setIsOpen={setIsOpen} />   
        <div className={`fixed top-0 z-50 w-full text-black transition-all duration-300 ease-out lg:px-12 ${scrolled ? "bg-white py-1.5 shadow-xl" : "bg-neutral-950/0 py-1 shadow-none"}`}>
          <div className='px-2 md:px-3 flex justify-between items-center py-1 md:py-2'>
            <Link to='/'>
              <img src={Logo} className='object-cover md:w-48 w-36' alt='Agaram-logo' />
            </Link>
            <div className="block md:hidden pt-1">
              <AnimatedHamburgerButton onClick={toggleMobileMenu} isActive={isMobileMenuOpen} />
            </div>
            <div className='hidden md:flex lg:gap-10 md:gap-5'>
              <FlyoutLink href="/" onClick={closeMobileMenu}>Home</FlyoutLink>
              <FlyoutLink href="/aboutus" onClick={closeMobileMenu}>About Us</FlyoutLink>
              <FlyoutLink href="/gallery" onClick={closeMobileMenu}>Gallery</FlyoutLink>
              <FlyoutLink FlyoutContent={<Services closeMobileMenu={closeMobileMenu} />}>Services</FlyoutLink>
              <FlyoutLink onClick={() => setIsOpen(true)} className="bg-gradient-to-r from-blue-800 to-blue-900 text-white font-medium px-4 py-2 rounded hover:opacity-90 transition-opacity" > Contact us </FlyoutLink>
            </div>
          </div>        
          <AnimatePresence>
            {isMobileMenuOpen && (
              <motion.div initial={{ opacity: 0, height: 0 }} animate={{ opacity: 1, height: "auto" }} exit={{ opacity: 0, height: 0 }} className="md:hidden shadow-lg text-sm" >
                <div className="flex flex-col p-4 gap-2">
                  <FlyoutLink href="/" onClick={closeMobileMenu}>Home</FlyoutLink>
                  <FlyoutLink href="/aboutus" onClick={closeMobileMenu}>About Us</FlyoutLink>
                  <FlyoutLink href="/gallery" onClick={closeMobileMenu}>Gallery</FlyoutLink>               
                  <FlyoutLink FlyoutContent={<Services closeMobileMenu={closeMobileMenu} />}>Services</FlyoutLink>
                  <FlyoutLink> <button onClick={() => { closeMobileMenu(); openModal(); }}>CONTACT US</button> </FlyoutLink>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        </div>
    );
  };

const FlyoutLink = ({ children, href, FlyoutContent, onClick }) => {
  const [open, setOpen] = useState(false);
  const showFlyout = FlyoutContent && open;

  return (
    <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}  onClick={onClick} className="relative w-fit h-fit" >
      <Link to={href} className="relative text-blue-800 font-extrabold font-Jose text-lg uppercase tracking-wide">
        {children}
        <span style={{ transform: open ? "scaleX(1)" : "scaleX(0)" }} className="absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-blue-80 transition-transform duration-300 ease-out" />
      </Link>
      <AnimatePresence>
        {showFlyout && (
          <motion.div className="absolute left-full md:left-[-100%] top-5 md:top-12 bg-white text-black w-48 ml-2.5 z-50"
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ duration: 0.3, ease: "easeOut" }}            
          >
            <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" />
            {FlyoutContent}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const Services = ({ closeMobileMenu }) => {
  return (
    <div className="p-6 shadow-xl z-[1000px] left-6">
      <div className="mb-3 space-y-3 font-Jose uppercase">
        <Link to="/xmlService" onClick={closeMobileMenu} className="block text-xs hover:underline hover:underline-offset-2">XML Conversion</Link>
        <Link to="/epubService" onClick={closeMobileMenu} className="block text-xs hover:underline hover:underline-offset-2">E-Publishing</Link>
        <Link to="/accessibilityService" onClick={closeMobileMenu} className="block text-xs hover:underline hover:underline-offset-2">Accessibility</Link>
        <Link to="/TypeSettingService" onClick={closeMobileMenu} className="block text-xs hover:underline hover:underline-offset-2">Typesetting</Link>
      </div>
    </div>
  );
};

const AnimatedHamburgerButton = ({ onClick, isActive }) => {
  return (
    <motion.button initial={false} animate={isActive ? "open" : "closed"} onClick={onClick} className="relative h-5 w-5 bg-white/0 transition-colors bg-blue-900" >
      <motion.span variants={VARIANTS.top} className="absolute h-0.5 w-3 bg-blue-900 mt-0.5" style={{ y: "-50%", left: "50%", x: "-50%", top: "35%" }} />
      <motion.span variants={VARIANTS.middle} className="absolute h-0.5 w-3 bg-blue-900 mt-0.5" style={{ left: "50%", x: "-50%", top: "50%", y: "-50%" }} />
    </motion.button>
  );
};

const VARIANTS = {
  top: {
    open: { rotate: "45deg", top: "50%" },
    closed: { rotate: "0deg", top: "35%" },
  },
  middle: {
    open: { rotate: "-45deg" },
    closed: { rotate: "0deg" },
  },
};

export default Navbar;
