import { useState } from "react";
import PIC from "../../Assets/Images/FAQ copy.jpg";

const FAQ = () => {

    const [accOpen, setAccOpen] = useState(null);

    const showAnswer = (id) => setAccOpen(accOpen === id ? null : id);

    return (
        <div>
            <div className="col-span-1 flex flex-col gap-5 justify-center items-center text-center mb-5 mt-10">  
                <h1 className="text-2xl md:text-4xl font-medium text-dark-brown capitalize">
                    <span className="border-b-2 font-Pop tracking-wide text-[#26313C]">Frequently Asked Questions</span>
                </h1>
                <p className="px-10 text-justify md:text-center tracking-wider text-gray-600 m-5 font-Pop font-medium ">Explore questions and answers regarding our design system, updates, and support to ensure a seamless <br />publishing experience..</p>      
            </div>       
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5bg-gray-100 lg:px-10 md:px-10 px-5 h-auto ">
                <div className="col-span-1 w-full h-full xl:h-[80vh] px-5 lg:px-12 py-5 md:py-10 lg:py-16 hidden md:flex">
                    <img src={PIC} alt="faqImage" className="w-full h-full rounded-xl shadow-2xl"/>
                </div>
                <div className="col-span-1 flex flex-col justify-center">   
                    {FAQDATA.map((item) => (
                        <div key={item.id} className="ease-in-out w-full border rounded-md py-2 md:py-4 mb-3 px-5 bg-white shadow-md transition-shadow duration-300 hover:shadow-lg">
                            <div className="flex justify-between items-center cursor-pointer" onClick={() => showAnswer(item.id)}>
                                <p className="text-sm md:text-md font-medium text-gray-800 font-Pop">{item.question}</p>    
                                <span className={`text-xl text-gray-600 transform transition-all delay-150 duration-300 ${accOpen === item.id ? 'rotate-360 transition-opacity ' : ''}`}>  {accOpen === item.id ? '-' : '+'} </span>
                            </div>                    
                            <div className={`mt-2 overflow-hidden transform transition-all delay-150 duration-300 ${accOpen === item.id ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                <p className="p-2 bg-white-yellow rounded-md font-Pop text-xs md:text-sm">{item.answer}</p>
                            </div>
                        </div>
                    ))}           
                </div>
            </div>
        </div>
    );
};

export default FAQ;

const FAQDATA = [
    {
        id: 1,
        question: 'What is Agaram Publishing?',
        answer: 'Agaram Publishing is a startup focused on application development, e-publishing, and content management. We aim to bring technology solutions to the common man and improve literacy through innovative publishing practices.'    
    },
    { 
        id: 2,
        question: 'What services do you offer?',
        answer: 'We offer application development, e-publishing solutions, content management, staffing services, and document management systems.'
    },
    {
        id: 3,
        question: 'How can you help my publishing business?',
        answer: 'We provide e-publishing solutions and content management systems to improve your processes and engage readers.'
    },
    { 
        id: 4,
        question: 'Can you handle large projects?',
        answer: 'Yes, our team has extensive experience in managing large-scale application development projects. We employ agile methodologies to ensure timely delivery and quality assurance throughout the development lifecycle.'
    },
    {
        id: 5,
        question: 'How can I contact customer support?',
        answer: 'You can contact us through our websites contact form, or reach us directly via email or through our website’s chat feature. We are always here to help you with your inquiries!'
    }
];