import React, { useState, useEffect } from 'react';
import { Testimonia1, Testimonia2, Testimonia3, Testimonia4 } from '../../Assets/index';
import { FaStar } from 'react-icons/fa'; 

const TestimonialSection = () => {
  const TestimonialContent = [
    {
      id: 1,
      img: Testimonia1,
      content: 'I was impressed with the personalized service and attention to detail. The staff guided me every step of the way, and their insights were invaluable in refining my manuscript. Publishing with them was one of the best decisions I’ve made',
      name: 'John Mathew',
      text_block: 'Founder, Double Batch',
      rating: 5,
    },
    {
      id: 2,
      img: Testimonia2,
      content: 'As a first-time author, I was unsure about the publishing process, but this team made everything easy. From layout to distribution, they took care of every detail and communicated clearly throughout. I’m thrilled with the final result!',
      name: 'Jane Doe',
      text_block: 'CEO, Tech Corp',
      rating: 5,
    },
    {
      id: 3,
      img: Testimonia3,
      content: 'I had a fantastic experience publishing my book here. The team was approachable, knowledgeable, and went above and beyond to make sure my book was perfect. I would recommend them to any author looking for a reliable publishing partner.',
      name: 'Alice Smith',
      text_block: 'Marketing Head, Creative Co',
      rating: 5,
    },
    {
      id: 4,
      img: Testimonia4,
      content: 'They provided exceptional service, made the publishing process stress-free, and took my book to the next level. Their advice was spot-on, and the quality of work was outstanding. I couldn’t be happier with the outcome!',
      name: 'Bob Johnson',
      text_block: 'Product Manager, Innovations Inc',
      rating: 5,
    },
  ];

  const [current, setCurrent] = useState(0);
  const totalTestimonials = TestimonialContent.length;

  const nextTestimonial = () => {
    setCurrent((prev) => (prev + 1) % totalTestimonials);
  };

  const prevTestimonial = () => {
    setCurrent((prev) => (prev - 1 + totalTestimonials) % totalTestimonials);
  };

  useEffect(() => {
    const timer = setInterval(nextTestimonial, 5000);
    return () => clearInterval(timer);
  }, []);

  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <FaStar key={index} className={`h-5 w-5 ${index < rating ? 'text-yellow-500' : 'text-gray-300'}`} />
    ));
  };

  return (
    <section className=''>
      <p className='text-2xl md:text-3xl lg:text-5xl font-semibold my-5 mt-md:my-10 uppercase px-3 text-blue-900 font-Mont'>Our Testimonials</p>
      <div className='w-full lg:flex h-auto min-h-[70vh] my-auto bg-gray-900 px-10 md:px-24 xl:px-72 justify-between items-center place-content-center space-y-6 p-8'>
        {TestimonialContent.map((item, index) =>  index === current && (
          <div key={index} className="transition-transform transform duration-500 ease-in-out scale-105 ">
            <div>
              <h2 className='text-gray-300 text-sm md:text-lg lg:text-xl font-bold font-Manrope tracking-wider text-justify mb-5'>{item.content}</h2>
              <div className='flex gap-2 mt-2'>{renderStars(item.rating)}</div>
            </div>
            <div className='md:flex justify-between items-center mt-6'>
              <div className='flex gap-4 items-center'>
                <div className='w-28 h-28'>
                  <img src={item.img} alt='' className='rounded-full' />
                </div>
                <div className='text-white text-sm md:text-md font-Arvo tracking-wider'>
                  <p >{item.name}</p>
                  <span>{item.text_block}</span>
                </div>
              </div>
              <div className='flex text-white font-bold gap-3 md:gap-4 justify-end md:justify-normal'>
                <button className="flex items-center justify-center w-7 md:w-9 h-10 md:h-12 rounded-md bg-gray-600 transition-transform duration-300 ease-in-out hover:bg-gray-500 hover:scale-105" onClick={prevTestimonial} > &lt; </button>
              <button className="flex items-center justify-center w-7 md:w-9 h-10 md:h-12 rounded-md bg-gray-600 transition-transform duration-300 ease-in-out hover:bg-gray-500 hover:scale-105" onClick={nextTestimonial} > &gt; </button>
            </div>
            </div>
          </div>
        ) )}
      </div>
    </section> 
  )
}
export default TestimonialSection;
