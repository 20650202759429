import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import { FiAlertCircle } from "react-icons/fi";


const SpringModal = ({ isOpen, setIsOpen }) => {

    const navigate = useNavigate()
    const [formValues, setFormValues] = useState({
      user_name: "",
      user_email: "",
      user_phone: "",
      user_description: "",
    });
  
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
  
    const validate = () => {
      const newErrors = {};
      if (!formValues.user_name) newErrors.user_name = "Name is required";
      if (!formValues.user_email) {
        newErrors.user_email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(formValues.user_email)) {
        newErrors.user_email = "Invalid email address";
      }
      if (!formValues.user_phone) {
        newErrors.user_phone = "Phone number is required";
      } else if (!/^[0-9]{10}$/.test(formValues.user_phone)) {
        newErrors.user_phone = "Invalid phone number";
      }
      if (!formValues.user_description) {
        newErrors.user_description = "Description is required";
      } else if (formValues.user_description.split(" ").length > 300) {
        newErrors.user_description = "Description must be 300 words or less";
      }
      return newErrors;
    };
  
    const handleChange = (e) => {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
      });
  
      const newErrors = { ...errors };
      if (e.target.name === "user_name" && e.target.value) {
        delete newErrors.user_name;
      }
      if (e.target.name === "user_email" && /\S+@\S+\.\S+/.test(e.target.value)) {
        delete newErrors.user_email;
      }
      if (e.target.name === "user_phone" && /^[0-9]{10}$/.test(e.target.value)) {
        delete newErrors.user_phone;
      }
      setErrors(newErrors);
    };
  
    const handleDescriptionChange = (e) => {
      if (e.target.value.split(" ").length <= 300) {
        handleChange(e);
        const newErrors = { ...errors };
        if (e.target.value) {
          delete newErrors.user_description;
        }
        setErrors(newErrors);
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const validationErrors = validate();
      setErrors(validationErrors);  
      navigate('/')
  
      if (Object.keys(validationErrors).length === 0) {
        emailjs
          .send(
            "service_jshli0y",
            "template_bbj0hzd",
            formValues,
            "CV077WGAge0EaQDzX"
          )
          .then(() => {
            setIsSubmitted(true);
            setTimeout(() => {
              setIsSubmitted(false);
            }, 3000);
            setFormValues({
              user_name: "",
              user_email: "",
              user_phone: "",
              user_description: "",
            });
          })
          .catch((error) => {
            console.error("Email send error:", error);
          });
      }
    };
  
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className="fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
        >
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className=" border-gray-800 bg-[#032556] text-white p-6 rounded-lg w-[90%] max-w-lg shadow-xl cursor-default relative overflow-hidden"
          >
            <FiAlertCircle className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
            <div className="px-2 md:px-5 ">         
              <div className="text-end cursor-pointer" onClick={() => setIsOpen(false)}><span>X</span></div>
              <div className="w-full  opacity-80 text-white p-2 ">
                <p className="pt-3 text-center md:text-3xl mb-10 text-md "><span className='pb-2 border-b-2 border-white'>Get in Touch</span></p>      
                <div className=" mt-4">
                  <div className="md:col-span-2 space-y-6 text-black">
                    <form onSubmit={handleSubmit}>
                      <div className="flex gap-3">
                      <div className="pb-2 md:pb-5">
                        <input className="w-full px-2 md:px-4 lg:py-1.5 py-1 outline-none" type="text" name="user_name" placeholder="Name" onChange={handleChange} value={formValues.user_name} />
                        {errors.user_name && ( <div className="text-red-500">{errors.user_name}</div> )}
                      </div>
                      <div className="pb-2 md:pb-5">
                        <input className="w-full px-2 md:px-4 lg:py-1.5 py-1 outline-none" type="email" name="user_email" placeholder="Email" onChange={handleChange} value={formValues.user_email} />
                        {errors.user_email && ( <div className="text-red-500">{errors.user_email}</div> )}
                      </div>
                      </div>
                      <div className="pb-2 md:pb-5">
                        <input className="w-full px-2 md:px-4 lg:py-1.5 py-1 outline-none"  type="tel" name="user_phone" placeholder="Mobile No"  onChange={handleChange} value={formValues.user_phone} />
                        {errors.user_phone && ( <div className="text-red-500">{errors.user_phone}</div> )}
                      </div>
                      <div className="pb-2 md:pb-5">
                        <textarea className="w-full px-2 md:px-4 lg:py-1.5 py-1 outline-none" name="user_description"  placeholder="Description" onChange={handleDescriptionChange} value={formValues.user_description}
                        onKeyDown={(e) => { if ( e.target.value.split(" ").length >= 300 && e.key !== "Backspace" ) { e.preventDefault(); } }} />             
                      </div>
                      <div className="flex justify-center">
                        <button className="border-2 px-5 lg:px-10 lg:py-2 py-0.5 text-white rounded-2xl text-md font-semibold tracking-widest"
                          type="submit" > Submit </button>
                      </div>
                    </form>
                    {isSubmitted && (
                      <div className="fixed top-5 right-5 bg-green-500 text-white px-7 py-2 rounded shadow-md"> Successfully submitted! </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default SpringModal;