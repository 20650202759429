import React, { useState } from "react";
import { tick } from '../../Assets/index';
import WHYUS from "../../Assets/Images/whyUs.jpg";
import MOTTO from "../../Assets/Images/motto.jpg";
import WEOFFER from "../../Assets/Images/weoffer.jpg";

const CardsData = [
  {
    id: 1,
    img: MOTTO,
    title: "Our Mission",
    desc: "In today’s fast-paced world, we value team members who can make quick, informed decisions. We empower our executives independently.",
    detail1: 'Customer Service and Satisfaction',
    detail2: 'Qualitative service',
    detail3: 'On time Value addition',
    detail4: 'Employee growth and satisfaction',
  },
  {
    id: 2,
    img: WHYUS,
    title: "Why Agaram?",
    detail1: 'We act as your needs-focused consultant, not only reseller',
    detail2: 'Complete tech solutions, end-to-end',
    detail3: 'We prioritize single-window application development.',
    detail4: 'You are our priority!',
  },
  {
    id: 3,
    img: WEOFFER,
    title: "What We Offer",
    detail1: 'Data Conversion',
    detail2: 'Software Development & Products',
    detail3: 'Human Resource Consulting',
    detail4: 'Training & Placements',
  },
];

const AnimatedCard = () => {
  const [hoveredCard, setHoveredCard] = useState(null); 

  return (
    <div className="container mx-auto">
      <h1 className="text-center text-gray-600 font-bold text-2xl md:text-4xl mb-7 md:mb-14 mt-5 sm:mt-7 uppercase font-Jose">Our Vision and Distinction</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 place-items-center gap-6 md:gap-1 lg:gap-6 mb-16 md:mb-20">
        {CardsData.map(({ id, img, title, desc, detail1, detail2, detail3, detail4 }) => (
          <div className="px-3" key={id}>
            <div onMouseEnter={() => setHoveredCard(id)} onMouseLeave={() => setHoveredCard(null)} className="text-white shadow-md rounded-lg overflow-hidden relative group cursor-pointer" >
              <img src={img} alt="" className="w-full aspect-square h-auto rounded-lg object-cover shadow-lg" />
              <div className={`absolute left-0 top-0 p-4 md:p-1 lg:p-4 w-full h-full bg-black/60 transition-all duration-500 ${ hoveredCard === id ? "opacity-100 top-0" : "opacity-0 top-[-100%]" }`} >
                <div className="space-y-4 md:space-y-1 lg:space-y-4 p-2 font-Pop">
                  <h1 className="text-2xl font-bold tracking-wider uppercase font-Pop">{title}</h1>
                  <p style={{ whiteSpace: "pre-wrap", fontSize: "15px" }}>{desc}</p>
                  {[detail1, detail2, detail3, detail4].map((detail, index) => (
                    <div key={index} className="grid grid-cols-12 items-center gap-2 px-2">
                      <img className="col-span-2 w-5 h-5" src={tick} alt="tick" />
                      <p className="col-span-10" style={{ whiteSpace: "pre-wrap", fontSize: "14px" }}>{detail}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnimatedCard;
