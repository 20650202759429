import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div className="relative overflow-hidden">
        <section className="h-auto lg:h-screen bg-[#F5FCFE]">
            <Content />
            <WatermarkWrapper />
        </section>
    </div>
  );
};

export default Hero;

const Content = () => {
  return (
    <div className="px-1.5 md:px-5 absolute top-20 md:top-44 2xl:top-60 bottom-0 left-0 right-0 z-40 md:border-b">
        <div className="mx-auto flex items-end justify-between p-4 md:p-8">
            <div className="font-Jose">
                <h1 className="mb-6 text-4xl font-black leading-[1.1] text-slate-900 md:text-[6.5rem] lg:text-[6.5rem] 2xl:text-[10rem]">
                    DATA <br /><span className="text-blue-80">CONVERSION</span>
                </h1>
                <p className="text-slate-700 md:text-lg uppercase tracking-widest">
                  Publishing & Technologies
                </p>
            </div>
        </div>
    </div>
  );
};

const WatermarkWrapper = () => {
  return (
    <>
        <Watermark text="XML-CONVERSION" />
        <Watermark text="ACCESSIBILITY" reverse />
        <Watermark text="TYPE-SETTING" />
        <Watermark text="XML-CONVERSION" reverse />
        <Watermark text="ACCESSIBILITY" />
        <Watermark text="TYPE-SETTING" reverse />
        <Watermark text="XML-CONVERSION" />
        <Watermark text="ACCESSIBILITY" reverse />
        <Watermark text="XML-CONVERSION" />
        <Watermark text="ACCESSIBILITY" reverse />
    </>
  );
};

const Watermark = ({ reverse, text }) => (
    <div className="flex -translate-y-12 select-none overflow-hidden font-Archivo opacity-80">
        <TranslateWrapper reverse={reverse}>
            <span className="w-fit whitespace-nowrap text-[10vmax] font-normal uppercase leading-[0.75] text-slate-300">
                {text}
            </span>
        </TranslateWrapper>
        <TranslateWrapper reverse={reverse}>
            <span className="w-fit whitespace-nowrap text-[10vmax] font-normal uppercase leading-[0.75] text-slate-300">
                {text}
            </span>
        </TranslateWrapper>
    </div>
);

const TranslateWrapper = ({ children, reverse }) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? "-100%" : "0%" }}
      animate={{ translateX: reverse ? "0%" : "-100%" }}
      transition={{ duration: 75, repeat: Infinity, ease: "linear" }}
      className="flex"
    >
      {children}
    </motion.div>
  );
};
