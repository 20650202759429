import React from 'react';
import { Clients, typeSettingImg } from '../../Assets';

const XmlService = () => {
  return (
    <div>
      <div className="h-[40vh] md:h-[60vh] w-full bg-cover bg-center" style={{ backgroundImage: `url(${Clients})`}}  >
        <div className=' bg-black-80 h-full w-full flex justify-center items-center'>
          <p className='text-2xl md:text-3xl lg:text-5xl text-white font-bold animate-fadeInUp p-2 text-center font-Jose tracking-wider'>TYPESETTING SERVICE</p>
        </div>
      </div>
      <div className="text-sm md:text-base grid grid-cols-1 md:grid-cols-2 gap-6 md:mt-10 mt-3 px-6 md:px-10 font-Archivo text-[#26313C] text-justify ">
        <div className='md:p-10 p-5 animate-fadeInLeft leading-7 tracking-wide'>
          <p>Transform your raw manuscript into a polished, press-ready format with our expert typesetting services. At Geethik, we are industry leaders in delivering high-quality typesetting solutions to clients worldwide, consistently striving to offer the very best across all domains.</p>
          <p className='mt-4'>Our team of skilled typesetters is dedicated to meeting the evolving needs of our clients, ensuring each document is meticulously organized with clean layouts, consistent styles, and precise formatting. We utilize a systematic approach and the latest technologies to deliver customized, exceptional typesetting services that elevate the quality of your publication.</p>
          <p className='mt-4'>This revision maintains professionalism, emphasizes expertise, and highlights the benefits of the services offered.</p>
        </div>
        <div className="flex justify-center items-center animate-fadeInRight">
          <img src={typeSettingImg} alt="XML Service" className="w-72 sm:w-96 md:w-[600px] transition-transform duration-300 hover:scale-105" />
        </div>
      </div>  
    </div>
  )
}
export default XmlService;
