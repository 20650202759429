import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useEffect } from "react";
import Lenis from "lenis";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer.jsx";
import About from './Pages/About/Aboutus.jsx'
import TestimonialSection from "./Pages/Testimonials/Testimonial2";
import Gallery from "./Pages/Gallery/Gallery";
import XmlService from "./Pages/Services/XmlService.jsx";
import EpubService from './Pages/Services/EpubService.jsx'
import AccessibilityService from './Pages/Services/AccessibilityService.jsx'
import TypeSettingService from './Pages/Services/TypeSettingService.jsx'
import Contact from './Pages/ContactForm/Form.jsx'
import ScrollToTop from './Components/ScrollToTop.jsx'
import Home from "./Pages/Home/Home.jsx";

function App() {

  useEffect(() => {
    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
  }, []);

  return (
    <BrowserRouter>
      <div className="bg-[#F5FCFE]">
        <Navbar/>   
        <ScrollToTop />    
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Aboutus" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/testimonials" element={<TestimonialSection />} />  
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/xmlService" element={<XmlService />} />
          <Route path="/epubService" element={<EpubService />} />
          <Route path="/accessibilityService" element={<AccessibilityService />} />
          <Route path="/TypeSettingService" element={<TypeSettingService />} />    
          <Route path="/ContactForm" element={<Contact />} />      
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
export default App;
